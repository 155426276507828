



















































































































































































import { FontAwesomeLayers } from "@fortawesome/vue-fontawesome";
import { extend } from "vee-validate";
import { max, required } from "vee-validate/dist/rules";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { ApiLoading, Attach, Choice, Event } from "@/api/structs";
import AppAvatar from "@/components/AppAvatar.vue";
import AppControlAutocomplete from "@/components/AppControlAutocomplete.vue";
import AppControlImage from "@/components/AppControlImage.vue";
import AppControlTaginput from "@/components/AppControlTaginput.vue";
import AppForm from "@/components/AppForm.vue";
import AppFormErrors from "@/components/AppFormErrors.vue";
import AppFormField from "@/components/AppFormField.vue";
import EventCreateCityModal from "@/modules/event/modals/EventCreateCityModal.vue";
import { choicesStore, usersStore } from "@/store";

extend("max", max);
extend("required", required);
extend("url", (value: string) => {
  try {
    new URL(value);
  } catch (_) {
    return false;
  }

  return true;
});

@Component({
  components: {
    EventCreateCityModal,
    AppControlTaginput,
    AppAvatar,
    AppControlImage,
    AppForm,
    AppFormErrors,
    AppControlAutocomplete,
    AppFormField,
    FontAwesomeLayers,
  },
})
export default class EventAdd extends Vue {
  @Prop({ required: true }) readonly isAdding!: boolean;
  @Prop({ required: true }) readonly event!: Event | null;
  @Prop({ required: true }) readonly choicesStyles!: Choice[];
  @Prop({ required: true }) readonly choicesCategories!: Choice[];
  @Prop({ required: true }) readonly apiLoading!: ApiLoading;

  private iAmOrganizerChoice: string = "";

  private typedCity: string = "";

  private id: string = "";
  private name: string = "";
  private cover: Attach | null = null;
  private summary: string = "";
  private link: string = "";
  private category: string = "";
  private place: string = "";
  private organizer: string = "";
  private dates: Date[] = [];

  private city: Choice | null = null;
  private styles: (Choice | string)[] = [];

  @Watch("link")
  onLinkUpdated(val: string) {
    try {
      new URL(val);
      return;
    } catch (_) {
      try {
        new URL(`https://${val}`);
        this.link = `https://${val}`;
      } catch (_) {
        return;
      }
    }
  }

  created() {
    if (this.isAdding) {
      return;
    }

    this.id = this.event?.id || "";
    this.name = this.event?.name || "";
    this.cover = new Attach("", "", "", this.event?.cover || ""); // FIXME "path" is empty
    this.summary = this.event?.summary || "";
    this.link = this.event?.link || "";
    this.category = this.event?.category || "";
    this.place = this.event?.place || "";
    this.organizer = this.event?.organizer || "";
    this.dates =
      this.event?.startsAt && this.event?.endsAt
        ? [this.event.startsAt.getDate(), this.event.endsAt.getDate()]
        : [];
    this.city = this.event
      ? this.choicesCities.find(el => el.name === this.event?.city) || null
      : null;

    this.styles = this.event
      ? this.choicesStyles.filter(el =>
          (this.event?.styles || []).find(el2 => el.name === el2),
        )
      : [];
  }

  get locale() {
    return this.$i18n.locale == "ru" ? "ru-RU" : "en-US";
  }

  noNewlines($event: any) {
    let keyCode = $event.keyCode ? $event.keyCode : $event.which;

    if (keyCode === 13) {
      $event.preventDefault();
    }

    return true;
  }

  get choicesCities() {
    return choicesStore.choices.cities;
  }

  setCity(city: Choice) {
    this.city = city;
  }

  setTypedCity(text: string) {
    this.typedCity = text;
  }

  openEventCreateCityModal() {
    EventCreateCityModal.open();
  }

  async submit() {
    let newStyles = [];

    for (const style of this.styles) {
      let styleChoice;

      if (typeof style === "string") {
        styleChoice = await choicesStore.createStyle({ name: style, groupId: null });
      } else {
        styleChoice = style;
      }

      newStyles.push(styleChoice);
    }

    await this.$emit("submitted", {
      payload: {
        id: this.id,
        name: this.name,
        summary: this.summary,
        description: "", // SEE: https://github.com/nickgashkov/united-dance-product/issues/158
        status: "published", // SEE: https://github.com/nickgashkov/united-dance-product/issues/157
        link: this.link,
        // @ts-ignore
        cover: this.cover?.path || undefined,
        // `toISOString` messes up timezones
        //
        // d
        // Thu Feb 24 2022 14:00:02 GMT+0100 (Central European Standard Time)
        // d.toISOString()
        // '2022-02-24T13:00:02.679Z'
        startDate: new Date(
          this.dates[0].getTime() - this.dates[0].getTimezoneOffset() * 60000,
        )
          .toISOString()
          .split("T")[0],
        startTime: null,
        endDate: new Date(
          this.dates[1].getTime() - this.dates[1].getTimezoneOffset() * 60000,
        )
          .toISOString()
          .split("T")[0],
        endTime: null,
        place: this.place,
        organizer:
          this.iAmOrganizerChoice === "y"
            ? usersStore.user?.fullName || ""
            : this.organizer,
        iAmOrganizer: this.iAmOrganizerChoice === "y",
        cityId: this.city?.id,
        category: this.category,
        level: "",
        stylesIds: newStyles.map((s: Choice) => s.id),
      },
    });
  }
}
