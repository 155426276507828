



















import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component
export default class AppControlTaginput extends Vue {
  // Same props as in the `BTaginput`.
  @VModel({ required: true }) readonly tags!: any[];
  @Prop({ required: true }) readonly idKey!: string;
  @Prop({ required: true }) readonly nameKey!: string;
  @Prop({ required: true }) readonly data!: any[];

  private typed: string = "";
  private filteredData: any[] = [];

  mounted() {
    this.filteredData = this.data;
  }

  addTag() {
    // @ts-ignore
    this.$refs.taginput.addTag(this.typed);
  }

  filterData(text: string) {
    this.typed = text;

    if (!text) {
      this.filteredData = this.data;
    } else {
      this.filteredData = this.data.filter(option => {
        return (
          option[this.nameKey].toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
        );
      });
    }

    this.filteredData = this.filteredData.filter(option => {
      return !this.tags.some(el => el[this.idKey] === option[this.idKey]);
    });
  }
}
