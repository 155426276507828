







































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { Choice } from "@/api/structs";
import AppControlAutocomplete from "@/components/AppControlAutocomplete.vue";
import AppForm from "@/components/AppForm.vue";
import AppFormField from "@/components/AppFormField.vue";
import AppFormSubmit from "@/components/AppFormSubmit.vue";
import AppModal from "@/components/AppModal.vue";
import { Api, Modal } from "@/constants";
import { choicesStore, modalsStore } from "@/store";

@Component({
  components: {
    AppControlAutocomplete,
    AppForm,
    AppFormField,
    AppFormSubmit,
    AppModal,
  },
})
export default class EventCreateCityModal extends Vue {
  @Prop({ required: true }) readonly initialName!: string;

  private name: string = "";
  private country: Choice | null = null;

  @Watch("initialName")
  onInitialNameChanged(val: string) {
    this.name = val;
  }

  static open() {
    modalsStore.open(Modal.CreateCity);
  }

  get isOpen() {
    return modalsStore.isOpen(Modal.CreateCity);
  }

  close() {
    modalsStore.close(Modal.CreateCity);
  }

  get apiLoading() {
    return choicesStore.loading(Api.ChoicesCreateCity);
  }

  get countries() {
    return choicesStore.choices.countries;
  }

  async submit() {
    const city = await choicesStore.createCity({
      groupId: this.country?.id || null,
      name: this.name,
    });
    await this.$emit("added", city);
    await this.close();
  }
}
