












import { Component, Prop, Vue } from "vue-property-decorator";

import { Choice } from "@/api/structs";
import { Api } from "@/constants";
import EventAdd from "@/modules/event/add/components/EventAdd.vue";
import { choicesStore, eventsStore } from "@/store";

interface Event {
  payload: EventPayload;
}

interface EventPayload {
  id: string;
  name: string;
  summary: string;
  status: string;
  description: string;
  link: string;
  cover: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  place: string;
  organizer: string;
  iAmOrganizer: boolean;
  cityId: string;
  category: string;
  stylesIds: string;
  level: string;
}

@Component({ components: { EventAdd } })
export default class EventAddContainer extends Vue {
  @Prop({ required: true }) readonly isAdding!: boolean;

  get event() {
    return eventsStore.event;
  }
  get choicesCities() {
    return choicesStore.choices.cities;
  }
  get choicesStyles() {
    return choicesStore.choices.styles;
  }
  get choicesCategories() {
    return [
      new Choice("workshop", "workshop", null),
      new Choice("intensive", "intensive", null),
      new Choice("party", "party", null),
      new Choice("camp", "camp", null),
      new Choice("casting", "casting", null),
      new Choice("course", "course", null),
      new Choice("battle", "battle", null),
      new Choice("laboratory", "laboratory", null),
      new Choice("festival", "festival", null),
      new Choice("jam", "jam", null),
      new Choice("ball", "ball", null),
      new Choice("show", "show", null),
      new Choice("lecture", "lecture", null),
    ];
  }
  get apiLoading() {
    return eventsStore.loading(Api.EventsCreateEvent);
  }

  async sendEvent($event: Event) {
    let api;
    if (this.isAdding) {
      api = Api.EventsCreateEvent;
      await eventsStore.createEvent({
        name: $event.payload.name,
        summary: $event.payload.summary,
        status: $event.payload.status,
        description: $event.payload.description,
        link: $event.payload.link,
        cover: $event.payload.cover,
        startDate: $event.payload.startDate,
        startTime: $event.payload.startTime,
        endDate: $event.payload.endDate,
        endTime: $event.payload.endTime,
        place: $event.payload.place,
        organizer: $event.payload.organizer,
        iAmOrganizer: $event.payload.iAmOrganizer,
        cityId: $event.payload.cityId,
        category: $event.payload.category,
        stylesIds: $event.payload.stylesIds,
        level: $event.payload.level,
      });
    } else {
      api = Api.EventsUpdateEvent;
      await eventsStore.updateEvent({
        id: $event.payload.id,
        name: $event.payload.name,
        summary: $event.payload.summary,
        status: $event.payload.status,
        description: $event.payload.description,
        link: $event.payload.link,
        cover: $event.payload.cover,
        startDate: $event.payload.startDate,
        startTime: $event.payload.startTime,
        endDate: $event.payload.endDate,
        endTime: $event.payload.endTime,
        place: $event.payload.place,
        organizer: $event.payload.organizer,
        cityId: $event.payload.cityId,
        category: $event.payload.category,
        stylesIds: $event.payload.stylesIds,
        level: $event.payload.level,
      });
    }

    if (eventsStore.isSuccess(api)) {
      if (eventsStore.event?.id) {
        this.$router.push({
          name: "event-detail",
          params: { id: eventsStore.event.id },
          query: { backTo: this.$route.query.backTo },
        });
      } else {
        this.$router.push({ name: "event-list" });
      }

      if (this.isAdding) {
        this.$buefy.snackbar.open({
          type: "is-success",
          message: this.$t("events.form.addSuccess").toString(),
        });
      } else {
        this.$buefy.snackbar.open({
          type: "is-success",
          message: this.$t("events.form.editSuccess").toString(),
        });
      }
    }
  }
}
